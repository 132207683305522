.titleAboutUs {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 10px;
  padding: 10px;
  color: black;
  text-align: center;
}

.boxAboutUs {
  /* border: 1px solid; */
  border-radius: 20px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 30px;
  background: linear-gradient(to top, #fff278, #ffde26);
  box-shadow: rgb(46 46 46 / 35%) 0px 5px 8px;
  color: white;
  /* padding: 2rem; */
}

/* .boxService {
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(to top, #ffffff, #ececec);
  box-shadow: rgb(46 46 46 / 35%) 0px 5px 8px;
  color: black;
} */

.numberAboutUs {
  font-size: 40px;
  color: rgb(255, 217, 25);
  text-shadow: 1px;
}

.fontTitleAboutUs {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  width: 100%;
  height: 100%;
  top: 0;
  /* left: 50%; */
  text-align: center;
  color: #ffd415;
  text-shadow: 2px 2px #000000;
  background: rgb(107 114 128 / 0.5);
  font-size: 1.5rem;
  font-weight: bold;
}

@media only screen and (min-width: 768px) {
  .fontTitleAboutUs {font-size: 3rem;}
} 

@media only screen and (min-width: 1024px) {
  .fontTitleAboutUs {font-size: 4rem;}
}
