.boxForm {
  /* border: 1px solid; */
  width: 100%;
  height: 100%;
  background-color: white;
  /* padding-left: 2rem;
    padding-right: 2rem; */
}

.inputAreaForm {
  border: 1px solid #ababab;
  border-radius: 25px;
  padding: 6px;
  padding-left: 15px;
  color: black;
  width: 100%;
  /* height: 100%; */
}

/* .buttonForm {
  background: linear-gradient(to top, #9c5da7, #5b0977);
  color: rgb(255, 204, 21);
  width: 150px;
  border-radius: 30px;
  font-size: 1.1rem;
  margin: 10px;
  padding: 10px;
} */

/* .buttonFormHover:hover {
  background: linear-gradient(to top, #fdd246, #ffd726);
  color: rgb(238, 55, 255);
  width: 150px;
  border-radius: 30px;
  font-size: 1.1rem;
  margin: 10px;
} */

.buttonForm {
  border-radius: 1rem;
  background-color: #fde047;
  color: #000000;
  font-size: 1.2rem;
  overflow: hidden;
  padding: 0.5rem 1.5rem;
  position: relative;
  transition: 0.2s transform ease-in-out;
  z-index: 0;
}

.buttonFormHover {
  border-radius: 1rem;
  background-color: #fde047;
  color: #000000;
  font-size: 1.2rem;
  overflow: hidden;
  padding: 0.5rem 1.5rem;
  position: relative;
  transition: 0.2s transform ease-in-out;
  z-index: 0;
}

.buttonFormHover::after {
  background-color: #893f8a;
  border-radius: 1rem;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-100%, 0) rotate(10deg);
  transform-origin: top left;
  transition: 0.2s transform ease-out;
  will-change: transform;
  z-index: -1;
}

.buttonFormHover:hover::after {
  transform: translate(0, 0);
}

.buttonFormHover:hover {
  color: rgb(255, 255, 255);
  /* transform: scale(1.05); */
  will-change: transform;
}

.inputForm {
  border: 1px solid #ababab;
  border-radius: 25px;
  padding: 6px;
  padding-left: 15px;
  color: black;
  width: 100%;
}

.inputAutoCompleteForm {
  /* border: 1px solid #000000; */
  border-radius: 25px;
  padding: 6px;
  padding-left: 10px;
  /* color: black; */
  /* width: 100%; */
}

.titleForm {
  font-size: 2.5rem;
  color: #000000;
}

.myDatePicker fieldset.MuiOutlinedInput-notchedOutline {
  border-color: green;
  border-radius: 30px;
}

.myDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border-color: red;
}

.imgFuild {
  /* max-height: 100%; */
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.textError {
  color: red;
  padding-bottom: 8px;
}
