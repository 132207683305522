.newsAndEvents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 2rem 0;
    width: 100%;
    background: rgb(254,253,251);
    background: linear-gradient(180deg, rgba(254,253,251,1) 0%, rgba(211,162,181,1) 44%, rgba(159,80,145,1) 76%, rgba(103,59,112,1) 100%);
}