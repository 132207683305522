.bannerSection {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    width: 100%;
    overflow: hidden;
    background: rgb(241,239,226);
    background: radial-gradient(circle, rgba(241,239,226,1) 6%, rgba(252,230,217,1) 29%, rgba(234,200,199,1) 81%);
}
